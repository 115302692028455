import { CabButton } from "@CabComponents/CabButton";
import { CabTextInput } from "@CabComponents/CabTextInput";
import { Box, FormControl, FormLabel, Alert, Link } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link as RouterLink, useLocation, useNavigate} from "react-router-dom";
import colors from "../../colors";
import StepHeader from "../../components/Onboarding/StepHeader";
import { companyRegex, emailRegex, PAGE_URL } from "../../constants";
import { actions, ThunkDispatchType } from "../../store";


export interface FormInput {
  company: string;
}

const validateCompanyDomain = (value: string) => {
  const validEmail = emailRegex.test(value.toLowerCase());
  const validDomain = companyRegex.test(value.toLowerCase());
  return (validEmail || validDomain) ? true : "pattern";
};

const SSOSearchContainer = () => {
  const {
    control, formState: { errors, isSubmitting }, handleSubmit: handleFormSubmit, setError,
  } = useForm<FormInput>({
    defaultValues: { company: '' },
  });

  const [redirectLocation, setRedirectLocation] = useState<string | null>(null);

  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch<ThunkDispatchType>();

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    const redirect_url = search.get("redirect");
    if (redirect_url) {
      setRedirectLocation(redirect_url);
    }
  }, [location]);

  const handleSubmit = async (values: FormInput) => {
    const companyDomain = values["company"].toLocaleLowerCase().split("@");
    const response = await dispatch(actions.organization.fetchPublicOrgByDomain(
      companyDomain[companyDomain.length - 1]
    ));
    if (response.status === 200) {
      const orgCode = response.data["org_code"];
      let newUrl: string | null = null;
      if (redirectLocation) {
        newUrl = redirectLocation.slice(-1) === "/" ? 
          redirectLocation + `${orgCode}` : redirectLocation + `/${orgCode}`;
      } else {
        newUrl = PAGE_URL.LOGIN + `/${orgCode}`;
      }
      navigate(newUrl, {state: {from: { pathname: location.state?.from?.pathname } }});
    } else if (response.status === 404) {
      setError("company", {message: "Company does not exist", type: "does_not_exist"}, {shouldFocus: true});
    }
  };

  return <Box sx={{ backgroundColor: colors.greyBackdrop, height: '100%', width: '100%' }} overflow='auto'>
    <Box paddingTop={10}>
      <Box display="flex" flexDirection="column" alignItems="center" gap={2} width="100%">
        <StepHeader
          title="Search for your Company"
          text="Enter your company domain to get started"
        />
        <form onSubmit={handleFormSubmit(handleSubmit)} style={{ width: 'inherit' }}>
          <Box display="flex" flexDirection="column" width="100%" flex={1} alignItems="center">
            <Box display="flex" flexDirection="column" gap={3} marginTop={4} width="100%"
              maxWidth="350px" flex={1} alignItems="center" paddingLeft={1} paddingRight={1}
            >
              
              <FormControl sx={{ flex: 1, width: '100%' }}>
                <FormLabel>Company Domain</FormLabel>
                <Controller name="company" control={control} rules={{ required: true, validate: validateCompanyDomain }}
                  render={({ field: { ref, ...field } }) => (
                    <CabTextInput {...field} autoComplete="company" />
                  )}
                />
                {errors.company && (
                  <Alert severity="error" sx={{ marginTop: 1 }}>
                    {errors?.company?.type === 'does_not_exist' ? 'Company does not exist'
                      : errors?.company?.type === 'validate' ? "Enter a valid domain name i.e. example.com" : null}
                  </Alert>
                )}
              </FormControl>
              <CabButton
                size="large"
                type="submit"
                sx={{ width: '100%' }}
                disabled={isSubmitting}
              >
                Search
              </CabButton>
              <Link component={RouterLink} to={PAGE_URL.LOGIN} variant="body2" color={colors.black600}>
                Back to Login
              </Link>
            </Box>
          </Box>
        </form>
      </Box>
    </Box>
  </Box>;
};

export default SSOSearchContainer;